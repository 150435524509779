export var MatterTerminology = {
  Help: 'Help',
  ScrubEnquiryData: 'Scrub Enquiry Data',
  LeadDashboardLayout: 'Lead Dashboard Layout',
  EnquiryDashboardLayout: 'Enquiry Dashboard Layout',
  FirmDashboardLayout: 'Firm Dashboard Layout',
  Tasks: 'Tasks',
  PhoneCalls: 'Phone Calls',
  TasksFound: 'Tasks found',
  LoginActivity: 'Login Activity',
  NameAccess: 'Name Access',
  MarketingListAccess: 'Marketing List Access',
  Configuration: 'Configuration',
  Home: 'Home',
  Admin: 'Admin',
  GeneralDetails: 'General Details',
  GuidId: 'GuidId',
  Billed: 'Billed',
  BilledInxTax: 'Billed Fees (inc tax)',
  BilledFees: 'Billed Fees',
  PercentageEstimate: '% Estimate',
  PercentageExposure: '% Billed+WIP',
  PercentageEstimateShort: '% Est',
  Financials: 'Financials',
  MatterId: 'Matter Id',
  FileNumber: 'File Number',
  MatterNumber: 'Matter No',
  Logo: 'Device',
  Reference: 'Reference',
  ClientName: 'Client Name',
  Client: 'Client',
  ClientPhone: 'Client Phone',
  ClientEmail: 'Client Email',
  Clients: 'Clients',
  Status: 'Status',
  LastUpdate: 'Last Update',
  PublishedDate: 'Publish Date',
  InternalCode: 'Internal Code',
  FileLocation: 'File Location',
  Matter: 'Matter',
  Matters: 'Matters',
  StaffBillingSummary: 'Staff Billing Summary',
  MattersReferred: 'Matters Referred',
  MatterType: 'Matter Type',
  MatterTypeCategory: 'Matter Type Category',
  EstimatedCosts: 'Estimated Fees',
  BillingMethod: 'Billing Method',
  CostAgreementReceived: 'Cost Agreement',
  LongTitle: 'Long Title',
  Completed: 'Completed',
  Title: 'Title',
  Description: 'Description',
  State: 'State',
  FinancialBilled: 'Billed',
  TrustFund: 'Trust Fund',
  TrustBalance: 'Trust Balance',
  FundsRecovered: 'Funds Recovered',
  BilledTimeAndFees: 'Time & Fees',
  BilledCostAndPayments: 'Cost & Payments',
  Unbilled: 'Unbilled',
  UnbilledShort: 'Unbilled Fees (inc tax)',
  UnbilledFees: 'Unbilled Fees',
  UnbilledDisbursements: 'Unbilled Disbursements',
  UnbilledDisbursementsShort: 'Unbilled Disb',
  Price: 'Price',
  Deposit: 'Deposit',
  ExchangeDate: 'Exchange Date',
  CoolingOffDate: 'Cooling Off Date',
  DepositDueDate: 'Deposit Due Date',
  DepositPaidDate: 'Deposit Paid Date',
  EarliestPriorityDate: 'Earliest Priority Date',
  ApplicationNumber: 'Application Number',
  PublicationDate: 'Publication Date',
  PublicationNumber: 'Publication Number',
  GrantNumber: 'Grant Number',
  Class: 'Class',
  GoodsAndServices: 'Goods & Services',
  CostAgreementReceivedDate: 'Cost Agreement Received Date',
  CostAgreementRequired: 'Cost Agreement Required',
  UnbilledTimeAndFees: 'Time & Fees',
  Disbursements: 'Disbursements',
  UnbilledCostRecoveries: 'Cost Recoveries',
  UnbilledOfficePayments: 'Office Payments',
  UnbilledAnticipatedPayments: 'Anticipated Payments',
  TrustGeneralBalance: 'Trust Balance',
  DebtorsBalance: 'Debtors Balance',
  CurrentDebtorsBalanceIncTax: 'Current Debt',
  ThirtyDebtorsBalanceIncTax: '30 Day Debt',
  SixtyDebtorsBalanceIncTax: '60 Day Debt',
  NintyDebtorsBalanceIncTax: '90+ Day Debt',
  CurrentWIPBalanceExTax: 'Current Unbilled Fees',
  ThirtyWIPBalanceExTax: '30 Days Unbilled Fees',
  SixtyWIPBalanceExTax: '60 Days Unbilled Fees',
  NinetyWIPBalanceExTax: '90+ Days Unbilled Fees',
  Trust: 'Trust',
  TrustDeposits: 'Trust Deposits',
  TrustWithdrawal: 'Trust Withdrawal',
  TrustControlledMoneyBalance: 'Controlled Money Balance',
  TrustInvestmentsBalance: 'Trust Investments Balance',
  Role: 'Role',
  ABN: 'ABN',
  ACN: 'ACN',
  OrganisationType: 'Organisation Type',
  BusinessType: 'Business Type',
  OtherSide: 'Other Side',
  CategoryId: 'Category Id',
  Category: 'Category',
  CountryId: 'Country Id',
  Country: 'Country',
  ClientOnly: 'Client Only',
  Property: 'Property Type',
  PropertyType: 'Property Type',
  PropertyLabel: 'Property',
  Family: 'Family',
  BrandFamily: 'Brand/Family',
  Stage: 'Stage',
  MatterNotes: 'Notes',
  AwaitingInstructions: 'Awaiting Instructions',
  FIRBStatus: 'FIRB Status',
  Documents: 'Documents',
  SearchDocument: 'Search documents...',
  Messages: 'Messages & Notes',
  SearchMessages: 'Search messages & notes...',
  Timeline: 'Timeline',
  SearchTimeline: 'Search Timeline...',
  Overdue: 'Overdue',
  DueComing: 'Due Coming',
  Today: 'Today',
  CompletedOnly: 'Completed',
  UploadedOn: 'Uploaded on',
  CreatedOn: 'Created on',
  By: ' by ',
  Notes: 'Notes: ',
  Download: 'Download',
  SummaryList: 'Summary List',
  Summary: 'Summary',
  UserList: 'User List',
  LeadsSummary: 'Leads Summary',
  Leads: 'Leads',
  EnquiriesSummary: 'Enquiries Summary',
  Enquiries: 'Enquiries',
  KeyOpportunity: 'Key Opportunity',
  Enquiry: 'Enquiry',
  EnquiryDetails: 'Enquiry Details',
  MyEnquiries: 'New Enquiries',
  MyKeyOpportunities: 'Key Opportunities',
  SearchNewEnquiries: 'Search Enquiries',
  SearchNewKeyOpportunity: 'Search Key Opportunity',
  MyNewEnquiries: 'My Enquiries',
  MyNewKeyOpportunity: 'My Key Opportunities',
  EnquiriesDashboard: 'Enquiries Dashboard',
  LeadsDashboard: 'Leads Dashboard',
  User: 'User',
  RoleSummary: 'Role Summary',
  Roles: 'Roles',
  SendFailures: 'Send Failures',
  MatterAccess: 'Matter Access',
  MessageActivity: 'Message Activity',
  TimesheetActivity: 'Timeline Activity',
  TimelineAnalytics: 'Timeline Analytics',
  MatterAnalytics: 'Matter Analytics',
  SendFailureAnalytics: 'Send Failure Analytics',
  NameAnalytics: 'Name Analytics',
  MarketingListAnalytics: 'Marketing List Analytics',
  LoginAnalytics: 'Login Analytics',
  SearchEntries: 'Search Entries',
  SearchMatters: 'Search Matters',
  SearchStaffBudget: 'Search Staff',
  SearchClientsContacts: 'Search Clients & Contacts',
  SearchTasks: 'Search Tasks',
  IncludeCompleted: 'Include Completed',
  IncludeDead: 'Include Dead',
  IncludeInactive: 'Include Inactive',
  IncludeIndividuals: 'Include Individual',
  IncludeOrganisations: 'Include Organisations',
  PMSContacts: 'PMS Contacts',
  EnterContacts: 'Entered Contacts',
  Search: 'Search',
  Email: 'Email',
  Phone: 'Phone',
  Address: 'Address',
  Staff: 'Staff',
  Scope: 'Scope',
  Recipient: 'Recipient',
  RoleCategory: 'Role Category',
  Reports: 'Reports',
  ReportsCatalog: 'Reports Catalog',
  ServiceLevels: 'Service Levels',
  OurFirm: 'Our Firm',
  NewMessageNotes: 'NEW MESSAGE/NOTE',
  NewMessage: 'NEW MESSAGE',
  NewNote: 'NEW NOTE',
  TypeNote: 'Type Note Here...',
  TypeMessage: 'Type Message Here...',
  SendMessageAction: 'SEND',
  Send: 'Send',
  Cancel: 'Cancel',
  Dashboard: 'Dashboard',
  DashboardLayout: 'Dashboard Layout',
  FirmDashboard: 'Firm Dashboard',
  TaskDashboard: 'Task Dashboard',
  ClientDashboard: 'Client Dashboard',
  FeeEarnerDashboard: 'Fee Earner Dashboard',
  FeeEarnerDashboardLayout: 'Fee Earner Dashboard Layout',
  TaskDashboardLayout: 'Task Dashboard Layout',
  ClientDashboardLayout: 'Client Dashboard Layout',
  MattersSummary: 'Matters',
  MatterManagement: 'Matter Management',
  Logout: 'Logout',
  ChangePassword: 'Change Password',
  CurrentPassword: 'Current Password',
  NewPassword: 'New Password',
  ConfirmPassword: 'Confirm Password',
  Save: 'Save',
  SupportedBrowsers: 'Supported Browsers',
  MemberLogin: 'Login',
  Username: 'Username...',
  Password: 'Password...',
  RememberMe: 'Remember Me',
  ForgottenPassword: 'Forgotten Password?',
  Login: 'LOGIN',
  GoogleChrome: 'Google Chrome',
  Safari: 'Safari',
  MozillaFirefox: 'Mozilla Firefox',
  MicrosoftEdge: 'Microsoft Edge',
  InternetExplorer: 'Microsoft Internet Explorer',
  NoDocumentsAvailable: 'No documents available',
  NoMessage: 'No Message',
  Subject: 'Subject',
  InternalMessage: 'Internal Message',
  Party: 'Party',
  Parties: 'Parties',
  Due: 'Due',
  NoTimelineDatesSet: 'No timeline dates set',
  MatterTitle: 'Matter Title',
  General: 'General',
  MatterCategory: 'Matter Category',
  TypeId: 'TypeId',
  Type: 'Type',
  TypeClassification: 'Type Classification',
  TypeState: 'Type State',
  SubTypeID: 'Sub TypeId',
  SubType: 'Sub Type',
  LastComment: 'Last Comment',
  Last3Comments: 'Last 3 Comments',
  LastMessage: 'Last Message',
  Last3Messages: 'Last 3 Messages',
  SettlementDate: 'Settlement Date',
  SyncSystemID: 'Sync System Id',
  ResponsibleID: 'Responsible Id',
  Responsible: 'Responsible',
  ResponsibleShort: 'Person Resp',
  ResponsibleEmail: 'Responsible Email',
  ResponsiblePhone: 'Responsible Phone',
  ActingID: 'Acting Id',
  Acting: 'Acting',
  ActingEmail: 'Acting Email',
  ActingPhone: 'ActingPhone',
  AssistingID: 'Assisting Id',
  Assisting: 'Assisting',
  AssistingEmail: 'AssistingEmail',
  AssistingPhone: 'AssistingPhone',
  CompletionDate: 'Completion Date',
  InstructionDate: 'Instruction Date',
  InProgress: 'In Progress',
  IsVip: 'Is VIP',
  FIRB: 'FIRB',
  SplashTemplates: 'Splash Templates',
  UserDefinedHelp: 'User Defined Help',
  UserDashboard: 'User Dashboard',
  FeatureAccess: 'Feature Access',
  FeatureLicencing: 'Feature Licencing',
  RoleAccesss: 'Role Access',
  RoleSecurity: 'Role Data Access',
  MatterViewColumnSecurity: 'Matter View Column Security',
  ClientReference: 'Client Reference',
  Owner: 'Owner',
  Debtors: 'Debtors',
  NextRenewalDate: 'Next Renewal Date',
  NextDue: 'Next Due',
  NextActionDate: 'Next Action Date',
  NextAction: 'Next Action',
  NextActionItem: ' Next Action Item',
  Refresh: 'Refresh',
  ExportFile: 'Export File',
  SaveLayout: 'Save Layout',
  ConfigureViews: 'Configure Views',
  Print: 'Print',
  ViewConfiguration: 'View Configuration',
  ViewName: 'View Name',
  AvailableRole: 'Available for Role',
  ForSecurity: 'For Security Only',
  SharedView: 'Shared View',
  Delete: 'Delete',
  Settings: 'Settings',
  AddNew: 'Add New',
  EditGroup: 'Edit Grouping',
  FilterColumn: 'Filter Columns',
  GeneralInformation: 'General Information',
  Referrals: 'Referrals',
  Vendor: 'Vendor',
  Council: 'Council',
  ContactFirm: 'Contact Firm',
  InternalResponsibilities: 'Internal Responsibilities',
  Date: 'Date',
  RequestNewPassword: 'Request New Password',
  MultifactorAuthenticationMessage: 'Enter code',
  AuthenticationCode: 'Verification Code',
  Verify: 'VERIFY',
  VerificationFailed:
    'Verification code is incorrect. Please specify the correct code and try again',
  VerificationCodeResendSuccessMessage: 'Verification Code resent successfully',
  ResendVerificationCode: 'Resend Verification Code',
  MatterSummaryConfigKey: 'Key',
  MatterSummaryConfigTemplateName: 'View Name',
  MatterSummaryConfigId: 'Config Id',
  MatterSummaryConfigIsUserLevel: 'Is User level',
  MatterSummaryConfigIsRoleLevel: 'Is Role level',
  MatterSummaryConfigIsTenantLevel: 'Firm Default',
  MyDefault: 'My Default',
  TenantDefault: 'Tenant Default',
  FirmDefault: 'Firm Default',
  MatterSummaryConfigIsSecurity: 'Save as Security Template ',
  LeapSignIn: 'Sign in to Leap',
  MyViewOnly: 'My View Only',
  RoleColumnRoleId: 'Role Id',
  RoleColumnRoleName: 'Role',
  RoleColumnSelected: 'Select',
  RoleColumnIsDefault: 'Default',
  LeapReset: 'Reset LEAP Access',
  Name: 'Name',
  NameSummary: 'Name Summary',
  StaffSummary: 'Staff Summary',
  Names: 'Names',
  ClientsAndContacts: 'Clients & Contacts',
  Referrer: 'Referrer',
  Referrers: 'Referrers',
  TotalOwing: 'Total Owing',
  TimeslipSummary: 'Timeslip Summary',
  AttorneyDashboard: 'Attorney Dashboard',
  Timesheet: 'Timesheet',
  Timelines: 'Timelines',
  Innography: 'Innography',
  ClientSense: 'Client Sense',
  MailingList: 'Marketing List',
  InvoiceSummary: 'Invoice Summary',
  ReportingGroup: 'Reporting Group',
  ArchivedDate: 'Archived Date',
  PotentialTransfer: 'Potential Transfer',
  ReviewDate: 'Review Date',
  ClientContactNotes: 'Client & Contact Activity',

  // deb details
  OriginalDebt: 'Original Debt',
  CollectionCosts: 'Collection Costs',
  LegalCostsRecoverable: 'Legal Costs (Recoverable)',
  LegalCostsNonRecoverable: 'Legal Costs (Non-Recoverable)',
  TotalDisbursements: 'Total Disbursements',
  TotalInterest: 'Total Interest',
  TotalPayments: 'Total Payments',
  TotalOutstanding: 'Total Outstanding',
  EventsAndCampaigns: 'Events & Campaigns',
  NotesSummary: 'Notes Summary',
  PriorityCountry: 'Priority Country',
  GeneralMaterial: 'Knowledge Bank',
  InjuryDate: 'Injury Date',
  ProceedingsIssuedDate: 'Proceedings Issued Date',
  SunsetOrLimitationDate: 'Sunset or Limitation Date',
  HearingDate: 'Hearing Date',
  ClientDocuments: 'Client Documents',
  Trustee: 'Trustee',
  TrusteeEmail: 'Trustee Email',
  IsAwaitingInstructions: 'Awaiting Instructions',
  ClientType: 'Client Type',
  LastTrustTransactionDate: 'Last Client/Trust Transaction Date',
  LastTrustTransactionType: 'Last Client/Trust Transaction Type',
  currentDisbursementsUnbilledTax: 'Current Days Disb',
  thirtyDisbursementsUnbilledTax: '30 Days Disb',
  sixtyDisbursementsUnbilledTax: '60 Days Disb',
  ninetyDisbursementsUnbilledTax: '90+ Days Disb',
  ForecastFeesBilledThisMonth: 'F\'cast Fees Billed This Month',
  ForecastFeesBilledNextThreeMonths: 'F\'cast Fees Billed Next 3 Mth',
  ForecastFeesBilledNextSixMonths: 'F\'cast Fees Billed Next 6 Mth',
  ForecastFeesCollectedThisMonth: 'F\'cast Fees Collected This Month',
  ForecastFeesCollectedNextThreeMonths: 'F\'cast Fees Collected Next 3 Mth',
  ForecastFeesCollectedNextSixMonths: 'F\'cast Fees Collected Next 6 Mth',
  ForecastHoursThisMonth: 'F\'cast Hours This Month',
  ForecastHoursNextThreeMonths: 'F\'cast Hours Next 3 Mth',
  ForecastHoursNextSixMonths: 'F\'cast Hours Next 6 Mth',
  ExpectedFutureFees: 'Expected Future Fees',
  Injury: 'Injury'
};
